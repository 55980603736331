import React from 'react'
import cn from 'classnames'

import { ICompany } from '@monorepo/company_module'
import { OfficeItem } from './office_item'

type OfficesListProps = React.HTMLAttributes<HTMLDivElement> & {
  offices?: ICompany[]
}

export const OfficesList: React.FC<OfficesListProps> = ({
  className,
  offices,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      'd-flex flex-row justify-content-around align-items-center flex-grow-1',
      className
    )}
  >
    {offices?.map((office, idx) => (
      <OfficeItem key={idx} office={office} />
    ))}
  </div>
)
