import styled from 'styled-components'
import { DateBadge as Base } from './date_badge'

export * from './date_badge'

export const DateBadge = styled(Base)`
  border-radius: ${(props) => props.theme.baseRadius};
  padding: 12px 14px;
  padding: 6px 14px;
  text-align: center;
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 15;
  background-color: ${(props) => props.theme.colorMainDark};
  opacity: 0.9;

  & > * {
    /* display: block; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2px;
    color: ${(props) => props.theme.colorWhite};
    font-weight: 700;
    line-height: 1.2em;

    &:nth-child(1) {
      font-size: 18px;
    }
    &:nth-child(2) {
      font-size: 12px;
      text-transform: uppercase;
    }
    &:nth-child(3) {
      font-size: 10px;
    }
  }
`
