import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import invariant from 'invariant'

import { apiClient } from '@monorepo/core'
import { AuthContext } from './auth.context'
import * as api from '../api'
import {
  LoginDTO,
  RegistrationDTO,
  ForgotPasswordDTO,
  ResetPasswordDTO,
} from '../dto'

import { FormError } from '@monorepo/application_module/components/form_error'
import { clearTokens, getRefreshToken, getToken, setTokens } from './utils'
import { errorResponseInterceptor, requestInterceptor } from './interceptors'
import { useSwitchUser } from '../hooks/switch_user.hook'

export const AuthProvider: React.FC = ({ children }) => {
  const queryClient = useQueryClient()

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  // const authContext = useBaseAuth()

  const login = useCallback(async (credentials: LoginDTO) => {
    try {
      const { token, refresh_token } = await api.authenticate(credentials)

      setTokens(token, refresh_token)
      setIsAuthenticated(true)
    } catch (error) {
      throw FormError.create(error, 'Неверный логин или пароль')
    }
  }, [])

  const logout = useCallback(async () => {
    clearTokens()
    setIsAuthenticated(false)
  }, [])

  const refresh = useCallback(async () => {
    try {
      const current_refresh_token = getRefreshToken()

      if (current_refresh_token) {
        const { token, refresh_token } = await api.refreshTokens(
          current_refresh_token
        )
        setTokens(token, refresh_token)
        setIsAuthenticated(true)
      } else {
        clearTokens()
        setIsAuthenticated(false)
      }
    } catch (error) {
      clearTokens()
      setIsAuthenticated(false)
    }
  }, [])

  const registration = async (profile: RegistrationDTO) => {
    await api.register(profile)
  }
  const forgotPassword = async (credentials: ForgotPasswordDTO) => {
    await api.forgotPassword(credentials)
  }
  const resetPassword = async (credentials: ResetPasswordDTO) => {
    await api.resetPassword(credentials)
  }

  useEffect(() => {
    function storageHandler(event: StorageEvent) {
      if (event.key === 'token') {
        !event.newValue ? logout() : setIsAuthenticated(true)
      }
    }
    window.addEventListener('storage', storageHandler)
    return () => {
      window.removeEventListener('storage', storageHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isAuthenticated) {
      queryClient.invalidateQueries(undefined, { refetchActive: false })
    }
  }, [isAuthenticated, queryClient])

  const isSwitchedUser = useSwitchUser()

  const contextValue = {
    isAuthenticated,
    isSwitchedUser,
    login,
    logout,
    registration,
    forgotPassword,
    resetPassword,
    refresh,
  }

  const token = getToken()

  useEffect(() => {
    if (token) {
      refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const requestHandler =
      apiClient.interceptors.request.use(requestInterceptor)
    const responseHandelr = apiClient.interceptors.response.use(
      (config) => config,
      (error) => errorResponseInterceptor(error, logout)
    )
    return () => {
      apiClient.interceptors.request.eject(requestHandler)
      apiClient.interceptors.response.eject(responseHandelr)
    }
  }, [logout])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export function useAuthenticatation() {
  const context = useContext(AuthContext)

  invariant(context, 'Add [AuthProvider] to your app!')

  return context
}
