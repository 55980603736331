import React from 'react'

import { useMenu } from '@monorepo/menu_module'
import { ICompany } from '@monorepo/company_module'
import { Navigation } from './Navigation'
import { OfficesList } from '../../components/offices_list'

type BottomSectionProps = React.HTMLAttributes<HTMLElement> & {
  offices?: ICompany[]
}

const BottomSection: React.FC<BottomSectionProps> = ({ offices, ...props }) => {
  const { data: mainMenu = [] } = useMenu('main')

  return (
    <section {...props}>
      <div className="container et-clearfix">
        <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
          <div className="vc_column-inner">
            <div className="d-flex justify-content-between align-items-center flex-grow-1">
              {/* <CompositButton outline wide to="/catalogs">
                <FontAwesomeIcon icon={faBars} size="lg" />
                &nbsp; Каталоги
              </CompositButton> */}
              <OfficesList offices={offices} />
              <span style={{ color: '#fff' }}>|</span>
              <Navigation menu={mainMenu} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BottomSection
