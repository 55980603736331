import React from 'react'
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap'
import { Field, FieldProps, Form as FormikForm } from 'formik'

import { useOrdersStatusGroupsList } from '@monorepo/order_module'
import { AutoSave } from 'App/Components/FormFields'

type FilterFormProps = {
  //
}

export const FilterForm: React.FC<FilterFormProps> = () => {
  const { data: statusGroups } = useOrdersStatusGroupsList()

  return (
    <Form as={FormikForm}>
      <AutoSave debounceMs={250} />
      <ButtonGroup toggle>
        {statusGroups?.map((statusGroup, idx) => (
          <Field
            key={idx}
            name="statusGroup"
            type="radio"
            value={statusGroup.id}
          >
            {({ field }: FieldProps) => (
              <ToggleButton type="radio" variant="primary" {...field}>
                {statusGroup.name}
              </ToggleButton>
            )}
          </Field>
        ))}
      </ButtonGroup>
    </Form>
  )
}
