import { useCallback, useLayoutEffect, useRef } from 'react'
import { AnyFunction } from '../types'

export function useEvent<T extends AnyFunction>(fn: T) {
  const refFn = useRef(fn)

  useLayoutEffect(() => {
    refFn.current = fn
  }, [fn])

  const eventCb = useCallback(
    (...args: Parameters<T>) => {
      return refFn.current.apply(null, args)
    },
    [refFn]
  )

  return eventCb
}
