import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useCurrentUser } from '@monorepo/user_module'
import { UserButton } from './UserButton'
import { CartButton } from './CartButton'
import { MenuButton } from './MenuButton'
import { ThemeSwitcher } from 'App/Layout/components/theme-switcher'
// import { Logo } from 'App/Layout/components/logo'

type BottomSectionProps = {
  logoImage: string
}

const LogoImage = styled.img`
  max-height: 52px !important;
  margin-top: 3px;
`

export const BottomSection: React.FC<BottomSectionProps> = ({ logoImage }) => {
  const { data: user } = useCurrentUser()

  return (
    <div className="vc-row-770266">
      <div className="container et-clearfix">
        <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none pl-0 pr-0">
          <div className="vc_column-inner p-0">
            <div className="wpb_wrapper d-flex align-items-center justify-content-between w-100">
              <section className="d-flex w-100 align-items-center">
                <MenuButton logoImage={logoImage} />
                <Link to="/">
                  <LogoImage src={logoImage} alt="" />
                  {/* <Logo $height="50px" title="" /> */}
                </Link>
              </section>
              <section className="d-flex">
                <div className="header-vertical-separator hbe hide-default-false hide-sticky-false hbe-right solid vertical header-vertical-separator-722008">
                  <div className="line" />
                </div>
                <UserButton user={user} />
                {user && <CartButton />}
                <ThemeSwitcher />
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
