import styled, { css } from 'styled-components'
import { Card as Base } from './card'
import { variantStyle } from 'App/Resources/mixins/variant.mixin'

export * from './card'

export const Card = styled(Base).attrs((props) => ({
  $rounded: props.$rounded ?? true,
  // $padding: props.$padding || '0.75rem 1rem',
  $padding: props.$padding === false ? 0 : props.$padding || '0.75rem 1rem',
  // $border:
  //   typeof props.$border === 'boolean'
  //     ? props.$border === true
  //       ? `1px solid ${props.theme.colorGrey}`
  //       : 'none'
  //     : props.$border ?? `1px solid ${props.theme.colorGrey}`,
  $border:
    props.$border === false
      ? 0
      : props.$border || `1px solid ${props.theme.colorMain}`,
  height: props.height || 500,
  imageHeight: props.imageHeight || '50%',
}))`
  ${() => variantStyle}

  overflow: hidden;
  /* border: 1px solid ${(props) => props.theme.mz}; */
  border: ${(props) => props.$border};
  width: calc(100% - 1px);
  height: ${(props) => parseInt(String(props.height))}px;
  display: flex;
  flex-direction: column;

  /* true          false         undefined         value */
  /* default       0             default           value */

  & > *:not(header) {
    /* padding: 0.75rem 1rem; */
    padding: ${(props) => props.$padding};

    &:not(:last-child) {
      padding-bottom: 0;
    }

    /* &:last-child {
      padding-top: 0;
    } */
  }
  /* & > *:not(header) {
    padding: 0.75rem 1rem 0;

    &:last-child {
      padding-bottom: 0.75rem;
    }
  } */

  & > header {
    height: ${(props) =>
      typeof props.imageHeight === 'number'
        ? `${props.imageHeight}px`
        : props.imageHeight};
    position: relative;
    overflow: hidden;
    text-align: center;
    /* flex-basis: auto; */
    flex-shrink: 0;

    & > img {
      transition: transform 500ms ease-in, filter 500ms ease-out 250ms;
      object-fit: cover;
      width: 100% !important;
      height: 100% !important;
    }
  }

  & > main {
    overflow: hidden;
    height: ${(props) =>
      `calc(${parseInt(String(props.height))}px - ${props.imageHeight})`};
    border-top: 1px solid ${(props) => props.theme.colorGrey};
    flex: 1;
  }

  & > footer {
    /*  */
  }

  ${(props) => {
    if (props.$rounded) {
      return css`
        border-radius: ${props.theme.baseRadius};
      `
    }
  }}
`
