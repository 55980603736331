import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'

import { IMenu } from '@monorepo/menu_module'
import { Menu } from './Menu'

import './style.scss'

type NavigationProps = {
  menu: IMenu
}

const Container = styled.nav`
  /* top: -10px; */
`

export const Navigation: React.FC<NavigationProps> = ({ menu }) => (
  <Container
    // id="header-menu-container-622660"
    className={cn(
      'header-menu-container',

      'menu-hover-box',
      'menu-hover-fill',
      'menu-hover-outline',

      'tl-submenu-ind-true',
      'submenu-appear-move',

      'submenu-shadow-true',
      'submenu-hover-line',

      'one-page-active',

      'separator-true',
      'top-separator-true',

      // ------------
      // 'menu-hover-overline',
      // 'menu-hover-underline',
      'sl-submenu-ind-true',
      'hbe'

      // 'hbe-left',
      // 'one-page-offset-0',
      // 'submenu-hover-line',
      // 'hide-sticky-false',
      // 'hide-default-false',
      // 'submenu-hover-none',
      // 'tl-submenu-ind-false',
      // 'separator-false',
      // 'top-separator-false',
      // 'hide-sticky-true',
      // 'hide-default-true'
    )}
  >
    <Menu items={menu} className="header-menu" />
  </Container>
)
