import styled from 'styled-components'

export const SearchControl = styled.div<{ $isDark?: boolean }>`
  display: flex;
  overflow: auto;
  border-radius: ${(props) => props.theme.baseRadius};
  border: 2px solid
    ${(props) =>
      props.$isDark ? props.theme.colorBlack : props.theme.colorMain};
  background-color: ${(props) => props.theme.colorWhite};

  & > input {
    background-color: ${(props) => props.theme.colorWhite};
    color: ${(props) => props.theme.colorBlack};
    font-weight: ${(props) => props.theme.fontSemibold};
    border: none;
    border-radius: 0;
    box-shadow: none;
    text-transform: uppercase;
    padding: 0.5em 1em;

    &::placeholder {
      color: ${(props) => props.theme.colorMainLight};
      text-transform: none;
      font-weight: normal;
    }
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 1em;
    padding: 0.5em 1em;
    background-color: transparent;
    color: ${(props) => props.theme.colorMainDark};

    &[type='submit'] {
      background-color: ${(props) => props.theme.colorMain};
      color: ${(props) => props.theme.colorWhite};
      font-size: 1em;
      & > * {
        margin: 0.25em;
      }
      & > strong {
        font-weight: ${(props) => props.theme.fontSemibold};
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
`
