import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import { ICompany } from '@monorepo/company_module'
import { renderFullAddress } from '@monorepo/application_module/helpers'
import { PhoneLink } from 'App/Components'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type OfficeItemProps = {
  office: ICompany
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 0.75em;

  padding: 0.25em 1.25em;
  background-color: ${(props) => props.theme.headerPrimaryColor};
  /* border-radius: ${(props) => props.theme.baseRadius}; */

  border-radius: 0.25em 0.125em 0.125em 0.25em;
  /* border-left: 0.5em solid ${(props) => props.theme.colorRed}; */
`
const Title = styled.strong`
  text-align: center;
  font-size: 1.2em;
`

const PhoneIcon = styled(FontAwesomeIcon).attrs({
  icon: faPhone,
  fixedWidth: true,
  size: 'lg',
})`
  color: ${(props) => props.theme.colorRed};
`

export const OfficeItem: React.FC<OfficeItemProps> = ({ office }) => {
  const phone =
    office.phones.filter((phone) => phone.isMain)[0] ?? office.phones[0]

  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={`tooltip-office-${office.id}`}>
          {renderFullAddress(office.address)}
        </Tooltip>
      }
    >
      <Wrapper>
        <Title>{office.name}</Title>
        <PhoneLink
          className="text-reset"
          phone={phone.number}
          title="Позвонить нам"
        >
          {/* <FontAwesomeIcon icon={faPhone} fixedWidth size="sm" /> */}
          <PhoneIcon /> {phone.number}
        </PhoneLink>
      </Wrapper>
    </OverlayTrigger>
  )
}
