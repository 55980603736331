import React from 'react'
import styled from 'styled-components'
import intersection from 'lodash/intersection'

import { IUser } from '@monorepo/user_module'
import { MenuItem } from './MenuItem'
import { useMenu } from '@monorepo/menu_module'

type PropsType = {
  user: IUser
}

const Delimiter = () => (
  <li className="account-nav__divider" role="presentation" />
)

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colorWhite};
  border: 2px solid ${(props) => props.theme.colorMain};
  border-radius: ${(props) => props.theme.baseRadius};
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */

  @media (max-width: 992px) {
    overflow-x: auto;
    max-width: 100%;
  }
`

const NavTitle = styled.h4`
  padding: 1.375rem 1.5rem;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;

  @media (max-width: 992px) {
    display: none;
  }
`

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 20px;

  @media (min-width: 992px) {
    padding-bottom: 1.375rem;
  }

  @media (max-width: 991.98px) {
    display: flex;
    white-space: nowrap;
    padding: 0 8px;
  }
`

export const AccountNavigation: React.FC<PropsType> = ({ user }) => {
  const { data: menu } = useMenu('cabinet')

  return (
    <Wrapper className="account-nav flex-grow-1">
      <NavTitle className="account-nav__title">Навигация</NavTitle>
      <NavList className="account-nav__list">
        {menu?.map((item, idx) => {
          return item.name === 'delimiter' ? (
            <Delimiter key={idx} />
          ) : !item.roles || intersection(item.roles, user.roles).length > 0 ? (
            <MenuItem key={idx} {...item} />
          ) : null
        })}
      </NavList>
    </Wrapper>
  )
}
