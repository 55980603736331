import styled from 'styled-components'

import { MenuItem as BaseMenuItem } from './MenuItem'

export type { MenuItemProps } from './MenuItem'

export const MenuItem = styled(BaseMenuItem).attrs((props) => ({
  className: props.active ? ('active' as string) : undefined,
}))`
  border: 1px solid transparent;
  border-left-width: 0.25rem;
  border-bottom-color: ${(props) => props.theme.colorGrey};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.25rem;
  /* margin: 0px 0.5rem; */
  margin: 0;
  transition: 350ms easy;

  &:last-child {
    border-bottom-color: transparent;
  }

  &.active {
    border: 1px dotted ${(props) => props.theme.colorDarkGrey};
  }
  &:hover,
  &.active {
    background-color: ${(props) => props.theme.colorGreyBg};
    border-left: 0.25rem solid ${(props) => props.theme.colorMainLight};

    & img {
      mix-blend-mode: multiply;
      border-color: ${(props) => props.theme.colorGrey};
    }
  }

  && {
    text-decoration: none;
  }
`
