import React from 'react'
import { Link } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { chunk } from 'lodash/fp'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faOdnoklassniki,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

import { IMenu, useMenu } from '@monorepo/menu_module'
import { ICompany } from '@monorepo/company_module'

import { SocialLinksList } from './social_links_list'

import { ScheduleTable } from 'App/Components/schedule_table'
import { ContactsInfo } from 'App/Components/contacts_info'
// import { Logo } from 'App/Layout/components/logo'

const Title = styled.strong`
  color: ${(props) => props.theme.colorWhite};
  text-decoration: underline;
`

type MiddleSectionProps = React.HTMLAttributes<HTMLElement> & {
  allOffices?: ICompany[]
  logoImage: string
}

const createGroups = (columns: number, items: IMenu) =>
  chunk(Math.ceil(items.length / columns))(items)

const socialLinks = [
  {
    name: 'Одноклассники',
    type: 'odnoklassniki',
    url: '/',
  },
  {
    name: 'Facebook',
    type: 'facebook',
    url: '/',
  },
  {
    name: 'Instagram',
    type: 'instagram',
    url: '/',
  },
]
const iconsMap: Record<string, IconDefinition> = {
  odnoklassniki: faOdnoklassniki,
  facebook: faFacebookF,
  instagram: faInstagram,
}

const LogoImage = styled.img`
  height: 75px;
`
const MiddleSection: React.FC<MiddleSectionProps> = ({
  logoImage,
  allOffices,
  ...props
}) => {
  const { data: items = [] } = useMenu('footerLinks')
  // const mainPhone =
  //   mainOffice?.phones.find((phone) => phone.isMain) ?? mainOffice?.phones?.[0]

  const linksColmnsAmount = 2
  const linksColmns = createGroups(linksColmnsAmount, items)

  return (
    <div {...props}>
      <Container>
        <Row>
          <Col
            md="3"
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Link to="/">
              <LogoImage src={logoImage} alt="" />
              {/* <Logo $height="75px" /> */}
            </Link>
            <SocialLinksList className="mt-4">
              {socialLinks.map((item, idx) => (
                <li key={idx}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.url}
                    title={`Мы в «${item.name}»`}
                  >
                    {typeof iconsMap[item.type] !== undefined && (
                      <FontAwesomeIcon
                        icon={iconsMap[item.type]}
                        size="2x"
                        fixedWidth
                      />
                    )}
                  </a>
                </li>
              ))}
            </SocialLinksList>

            {/* {mainPhone && (
              <PhoneNumber phone={mainPhone.number}>
                {(phoneNumber: string) => (
                  <>
                    <FontAwesomeIcon icon={faPhoneAlt} /> {phoneNumber}
                  </>
                )}
              </PhoneNumber>
            )} */}
          </Col>

          {/* {linksColmns.map((items, idx) => (
            <Col key={idx} md={Math.floor((12 - 6) / linksColmnsAmount)}>
              <LinksList items={items} />
            </Col>
          ))} */}
          {allOffices?.map((office, idx) => (
            <Col md="3" key={idx}>
              <Title className="lead">{office.name}</Title>
              <ContactsInfo office={office} />
              <Title className="lead">График работы:</Title>
              <ScheduleTable size="sm" schedule={office?.workSchedule} />
            </Col>
          ))}
          {/* {mainOffice && (
            <Col md="3">
              <Title className="lead">Наши контакты:</Title>
              <ContactsInfo office={mainOffice} />
              <Title className="lead">График работы:</Title>
              <ScheduleTable size="sm" schedule={mainOffice?.workSchedule} />
            </Col>
          )} */}
        </Row>
      </Container>
    </div>
  )
}

export default MiddleSection
