/* eslint-disable no-template-curly-in-string */
import { boolean, number, object, ref, SchemaOf } from 'yup'

import { formatDeliveryTime } from '@monorepo/application_module/helpers'
import { IOffer } from '@monorepo/application_module'
import { CreateOrderItemDTO } from '../dto'

export const createOrderItemSchema: SchemaOf<CreateOrderItemDTO> = object({
  id: number().required(),
  isSelected: boolean().required(),
  offer: object()
    .label('Предложение')
    .nullable()
    .default(undefined)
    .when('isSelected', {
      is: true,
      then: (schema) => schema.required('Товар закончился у поставщика'),
    }),

  amount: number()
    .label('Количество')
    .required()
    .when(['isSelected', 'offer'], {
      is: (isSelected: boolean, offer?: IOffer) => isSelected && !!offer,
      then: (schema) =>
        schema
          .positive()
          .when('offer.minAmount', {
            is: (min?: number) => typeof min === 'number',
            then: (schema) =>
              schema.min(
                ref('offer.minAmount'),
                'Минимальное количество для заказа ${min}'
              ),
          })
          .when('offer.amount', {
            is: (max?: number) => typeof max === 'number',
            then: (schema) =>
              schema.max(
                ref('offer.amount'),
                'Максимальное количество для заказа ${max}'
              ),
          })
          .when('offer.multiplicityAmount', {
            is: (multiplicity?: number) => typeof multiplicity === 'number',
            then: (schema) =>
              schema.multiplicity(
                ref('offer.multiplicityAmount'),
                'Кратность заказа ${multiplicity}'
              ),
          }),
    }),

  price: number()
    .label('Цена')
    .required()
    .when(['isSelected', 'offer'], {
      is: (isSelected: boolean, offer?: IOffer) => isSelected && !!offer,
      then: (schema) =>
        schema.positive().when('offer.price', {
          is: (min?: number) => typeof min === 'number',
          then: (schema) =>
            schema.min(
              ref('offer.price'),
              'Цена изменилась, новая цена ${min} руб.'
            ),
        }),
    }),

  deliveryTime: number()
    .label('Срок поставки')
    .required()
    .when(['isSelected', 'offer'], {
      is: (isSelected: boolean, offer?: IOffer) => isSelected && !!offer,
      then: (schema) =>
        schema.when('offer.deliveryTime', {
          is: (min?: number) => typeof min === 'number',
          then: (schema) =>
            schema.min(
              ref('offer.deliveryTime'),
              ({ min }) =>
                `Срок поставки изменился, новый срок ${formatDeliveryTime(min)}`
            ),
        }),
    }),
})
