import React from 'react'
import { toast } from 'react-toastify'

import { UpdatePasswordProvider } from '@monorepo/user_module'
import { UpdatePasswordForm } from './update_password.form'

export const AuthSetting: React.FC = () => (
  <div className="body p-0 p-md-4">
    <h6>Параметры авторизации</h6>
    <UpdatePasswordProvider
      onSuccess={() => {
        toast.success('Пароль успешно изменён')
      }}
      onError={(error) => {
        toast.error(error.message || 'При изменениии пароля произошла ошибка')
      }}
    >
      <UpdatePasswordForm />
    </UpdatePasswordProvider>
  </div>
)
