import React from 'react'
import { useFormikContext } from 'formik'
import { useDebounceEffect } from '@monorepo/shared/hooks/use-debounce-effect'

interface AutoSaveProps {
  debounceMs: number
  children?:
    | ((props: { isDirty: boolean; isSubmitting: boolean }) => React.ReactNode)
    | React.ReactNode
}

export const AutoSave: React.FC<AutoSaveProps> = ({
  debounceMs,
  children = null,
}) => {
  const formik = useFormikContext()

  useDebounceEffect(
    () => {
      console.log('Try to save')

      if (formik.dirty) {
        formik.submitForm()
      }
    },
    [formik.dirty, formik.values],
    debounceMs
  )

  return formik.dirty || formik.isSubmitting
    ? typeof children === 'function'
      ? children({
          isDirty: formik.dirty,
          isSubmitting: formik.isSubmitting,
        })
      : children
    : null
}
