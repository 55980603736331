import React from 'react'
import styled from 'styled-components'
import BaseContainer from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { AdvantageItem, IAdvantageItem } from './advantage_item'

import image1 from './images/5.jpg'
import image2 from './images/7.jpg'
import image3 from './images/9.jpg'
import { Title } from '../components/title'
import { SubTitle } from '../components'

const items: IAdvantageItem[] = [
  {
    image: image1,
    content:
      'Один из самых больших выборов автозапчастей для азиатских и европейских автомобилей. Вы можете подобрать как оригинальные детали, так и их качественные замены, подтвержденные сертификатами производителей.',
  },
  {
    image: image2,
    content:
      'Мы заключили прямые контракты с производителями автозапчастей, что позволяет нам гарантировать не только качество товара, но и предоставлять одни из самых низких цен.',
  },
  {
    image: image3,
    content:
      'Наши сотрудники – наша гордость. Если вы не знаете каталожный номер запчасти или сомневаетесь в правильности своего выбора, профессионалы с многолетним опытом проконсультируют и помогут вам с подбором необходимых деталей.',
  },
]

type AdvantagesSectionProps = {
  title: string
  subTitle: string
}

const Container = styled(BaseContainer)`
  margin-bottom: 55px;
  margin-top: 55px;
`

const ItemsContainer = styled(Row)`
  margin-top: 25px;
`

export const AdvantagesSection: React.FC<AdvantagesSectionProps> = ({
  title,
  subTitle,
}) => (
  <Container>
    <Row>
      <Col xs="12" className="d-flex flex-column">
        <SubTitle>{subTitle}</SubTitle>
        <Title className="align-self-end">{title}</Title>
      </Col>
    </Row>
    <ItemsContainer>
      {items.map((item, idx) => (
        <AdvantageItem key={idx} item={item} />
      ))}
    </ItemsContainer>
    {/* <Separator /> */}
  </Container>
)
