import React from 'react'
import styled from 'styled-components'

import { TableRow } from './offers_table/table.row'

type TableHeaderPropsType = {
  //
}

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colorMain};
  color: ${(props) => props.theme.colorWhite};
  border: ${(props) => props.theme.baseTableBorder};
  border-bottom: none;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const TableHeader: React.FC<TableHeaderPropsType> = () => (
  <div className="col-12 d-none d-md-block">
    <Wrapper className="row">
      <div className="col-sm-5 align-items-center">Наименование</div>
      <div className="col-sm-7">
        <div className="d-flex flex-column w-100 justify-content-between">
          <TableRow>
            <>&nbsp;</>
            <>
              <span className="col-8 text-nowrap text-center">Срок|Склад</span>
              <span className="col-4 text-nowrap text-center">Кол-во</span>
            </>
            <>Цена</>
            <>&nbsp;</>
          </TableRow>
        </div>
      </div>
    </Wrapper>
  </div>
)
