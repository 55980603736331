import { AnyFunction, Nullable } from '../types'

export function debounce<T extends AnyFunction>(fn: T, ms: number) {
  // let timeoutId: Nullable<number> = null
  let timeoutId: Nullable<ReturnType<typeof setTimeout>> = null

  function debounced(...args: Parameters<T>) {
    if (typeof timeoutId === 'number') {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
      timeoutId = null
      fn.apply(null, args)
    }, ms)
  }

  debounced.cancel = () => {
    if (typeof timeoutId !== 'number') {
      return
    }

    clearTimeout(timeoutId)
  }

  return debounced
}
