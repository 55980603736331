import React from 'react'
import { flow, map, reject } from 'lodash/fp'

import { ICatalog, useCatalogsList } from '@monorepo/catalogs_parts'
import { CatalogItem } from './catalog_item'
// import styled from 'styled-components'

export type CatalogsProps = {
  //
}

const icons: Record<string, string> = {
  to: 'enova-auto-car-41',
  lamps: 'enova-auto-car-parts-17',
  batteries: 'enova-auto-battery', //'enova-auto-car-parts-16',
  tires: 'enova-auto-tire1',
  diski: 'enova-auto-alloy-wheel-7',
  oils: 'enova-auto-oil-1',
  'bolty-gayki': 'enova-auto-car-parts-51',
  aksessuary: 'enova-auto-steering-wheel', // 'enova-auto-automotive-3', //'enova-auto-air-pump',
  window: 'enova-auto-car-10', //'enova-auto-window',
}

function isDark(col: number, colAmount: number) {
  return (col + (Math.floor(col / colAmount) % 2 === 0 ? 0 : -1)) % 2 !== 0
}

const addIcon = (catalog: ICatalog) => ({
  ...catalog,
  icon: icons[catalog.id],
})

const prepareData = flow(reject<ICatalog>({ id: 'caps' }), map(addIcon))

// const PageSection = styled(Page.Section)`
//   background-color: ${(props) => props.theme.colorBlack};
// `

export const Catalogs: React.FC<CatalogsProps> = () => {
  const { data: catalogs = [] } = useCatalogsList(undefined, {
    select: prepareData,
  })

  return (
    <div
      data-gap="24"
      className="column-4 et-icon-box-container et-item-set effect-none animation-type-sequential top no-border"
    >
      {catalogs
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        .map((item, idx) => (
          <CatalogItem key={idx} item={item} dark={isDark(idx, 4)} />
        ))}
    </div>
  )
}
