import React from 'react'
import { Field, Form } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faSave } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

import { ResetPasswordProvider } from '@monorepo/auth_module'
import { BaseFormGroup } from 'App/Components/FormFields'

type ResetPasswordSectionProps = {
  token: string
}

export const ResetPasswordSection: React.FC<ResetPasswordSectionProps> = ({
  token,
}) => (
  <ResetPasswordProvider
    token={token}
    onSuccess={() => {
      toast.success('Пароль успешно изменён')
    }}
    onError={(error) => {
      toast.error(
        <dl>
          {error.errors.map((error, idx) => (
            <dd key={idx}>{error.message}</dd>
          ))}
        </dl>
      )
    }}
  >
    {({ isSubmitting, isValid }) => (
      <Form
        className="form-auth-small ng-untouched ng-pristine ng-valid"
        noValidate
      >
        <BaseFormGroup name="email">
          <BaseFormGroup name="plainPassword[first]" label="Новый пароль">
            <Field
              className="form-control"
              placeholder="Новый пароль"
              type="password"
              name="plainPassword[first]"
              autoComplete="off"
            />
          </BaseFormGroup>
          <BaseFormGroup
            name="plainPassword[second]"
            label="Повторите новый пароль"
          >
            <Field
              className="form-control"
              placeholder="Повторите новый пароль"
              type="password"
              name="plainPassword[second]"
              autoComplete="off"
            />
          </BaseFormGroup>
        </BaseFormGroup>
        <button
          className="btn btn-primary btn-lg btn-block"
          type="submit"
          disabled={!isValid || isSubmitting}
        >
          Изменить пароль{' '}
          <FontAwesomeIcon
            icon={isSubmitting ? faCircleNotch : faSave}
            spin={isSubmitting}
            fixedWidth
            className="ml-1"
          />
        </button>
        {/* <div className="bottom">
          <span className="helper-text">
            Вспомнили пароль? <Link to="/login">Войти</Link>
          </span>
        </div> */}
      </Form>
    )}
  </ResetPasswordProvider>
)
