import styled from 'styled-components'

import { PostItem as Base } from './post_item'

export * from './post_item'

export const PostItem = styled(Base)`
  & {
    main {
      padding: 0;

      article {
        background-color: ${(props) => props.theme.colorMainLight};
        padding: 0.75rem;
        height: 100%;

        font-weight: ${(props) => props.theme.fontSemibold};
        font-size: 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* text-align: justify; */
        /* text-indent: 2rem; */
        /* hyphens: auto; */

        & a {
          color: ${(props) => props.theme.colorWhite};
          text-align: center;

          &:hover {
            text-decoration: none;
          }
        }

        & *[role='main'] {
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        & p {
          font-size: 0.8em;
          color: ${(props) => props.theme.colorWhite};
          text-align: justify;
          text-indent: 2rem;
          hyphens: auto;
          overflow: hidden;
          margin-bottom: 10px;
        }
      }
    }
  }
`
