import { AnyFunction, Nullable } from '../types'

export function throttle<T extends AnyFunction>(fn: T, ms: number) {
  let timeoutId: Nullable<ReturnType<typeof setTimeout>> = null
  let latestArgs: Nullable<Parameters<T>> = null

  function throttled(...args: Parameters<T>) {
    if (typeof timeoutId === 'number') {
      latestArgs = args
      return
    }
    fn.apply(null, args)

    timeoutId = setTimeout(() => {
      timeoutId = null
      if (latestArgs !== null) {
        throttled.apply(null, latestArgs)
        latestArgs = null
      }
    }, ms)
  }

  throttled.cancel = () => {
    if (typeof timeoutId !== 'number') {
      return
    }

    clearTimeout(timeoutId)
  }

  return throttled
}
