import { useQuery, UseQueryOptions } from 'react-query'

import { IGood } from '@monorepo/application_module'
import { fetchGoodsList } from '../api/fetch_goods_list'

const QUERY_KEY = 'goods-carousel'

type GoodsFilter = {
  category: string
}

export function useGoodsCarousel<TData = IGood[]>(
  params: string | GoodsFilter,
  options?: UseQueryOptions<IGood[], unknown, TData>
) {
  const filter: GoodsFilter =
    typeof params === 'string'
      ? {
          category: params,
        }
      : params
  return useQuery([QUERY_KEY, filter] as any, () => fetchGoodsList(filter), {
    refetchOnWindowFocus: false,
    staleTime: 15 * 60 * 1000,
    ...options,
  })
}
