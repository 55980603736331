import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import BaseContainer from 'react-bootstrap/Container'

import { useAuthenticatation } from '@monorepo/auth_module'
// import { PatronLogo } from 'App/Components'

import { ContractSwitcher } from './contract_switcher'
import { UserMenu } from './user_menu'
import { ThemeSwitcher } from '../../../components/theme-switcher'

type TopSectionProps = React.HTMLAttributes<HTMLElement> & {
  $isDark?: boolean
}

const InnerContainer = styled(BaseContainer)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.colorWhite};
  }
`

const UserIcon = styled.span.attrs({
  className: 'ien-euser-10',
})`
  padding-right: 5px;
  padding-bottom: 3px;
`

const Separator = styled.span`
  vertical-align: middle;
  display: inline-block;
  height: 24px;
  border-bottom: 24px solid ${(props) => props.theme.colorMainLight};
  width: 1px;
  margin: 0 0.5rem;
`

const TopSection: React.FC<TopSectionProps> = (props) => {
  const { isAuthenticated } = useAuthenticatation()

  return (
    <section {...props}>
      <InnerContainer>
        <section>
          <span>Сеть магазинов запчастей</span>
          <span>Интернет-магазин автозапчастей</span>
        </section>
        <section>
          {isAuthenticated ? (
            <>
              <ContractSwitcher />
              <Separator />
              <UserMenu />
            </>
          ) : (
            <>
              <Link to="/login">
                <UserIcon /> Вход
              </Link>
              <Separator />
              <Link to="/registration">Регистрация</Link>
            </>
          )}
          <ThemeSwitcher />
        </section>
      </InnerContainer>
    </section>
  )
}

export default TopSection
