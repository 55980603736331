import React from 'react'
import { FormattedDate } from 'react-intl'
import Container from 'react-bootstrap/Container'

import { Link } from 'App/Components'

export type BottomSectionProps = React.HTMLAttributes<HTMLElement> & {
  companyName: string
}

const BottomSection: React.FC<BottomSectionProps> = ({
  companyName,
  ...props
}) => {
  const companySite = window.location.hostname
  const companyFoundationDate = undefined

  return (
    <div {...props}>
      <Container>
        <div className="et-image alignnone curtain-left">
          <small>Powered by:</small>&nbsp;&nbsp;
          {/* eslint-disable-next-line react/jsx-no-target-blank*/}
          <a href="https://itonix.ru" target="_blank" rel="noopener">
            <img
              height="30"
              style={{ maxHeight: 30 }}
              src="https://itonix.ru/wp-content/uploads/2022/12/logo_itonix_white_300px.png"
              alt="itonix.ru"
            />
          </a>
        </div>

        <Link to="/" className="d-none d-md-block">
          <strong>{companySite}</strong>
          &nbsp; ©
          {companyFoundationDate && (
            <>
              <FormattedDate value={companyFoundationDate} year="numeric" />
              &nbsp;&mdash;&nbsp;
            </>
          )}
          <FormattedDate value={Date.now()} year="numeric" /> | {companyName}.
          Все права сохранены.
        </Link>
      </Container>
    </div>
  )
}

export default BottomSection
