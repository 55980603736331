import { apiClient } from '@monorepo/core'
import { IGood } from '@monorepo/application_module'

type FetchGoodsParams = {
  category: string
}

export async function fetchGoodsList(params: FetchGoodsParams) {
  try {
    const { data } = await apiClient.get<IGood[]>(
      `/carousel/${params.category}`
    )

    return data
  } catch (error) {
    throw error
  }
}
