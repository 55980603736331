import parse, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser'

import renderers from './renderers'

function replace(domNode: DOMNode) {
  if (
    domNode instanceof Element &&
    domNode.type === 'tag' &&
    domNode.name in renderers
  ) {
    const render = renderers[domNode.name]

    return render(domNode)
  }
}

export const parserOptions: HTMLReactParserOptions = {
  replace,
  trim: true,
}

export default (html: string) => parse(html, parserOptions)
