import React, { useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { Field, FieldAttributes, FieldProps, useField } from 'formik'
import { flow, groupBy, toPairs, map } from 'lodash/fp'

export interface SelectFieldProps<T>
  extends FieldAttributes<any>,
    React.HTMLAttributes<HTMLSelectElement> {
  name: string
  keyProp: keyof T
  valueProp: keyof T | ((item: T) => string)
  groupByProp?: keyof T | ((item: T) => string)
  items?: T[]
  disabled?: boolean
}

interface OptionGroup<T> {
  label: string
  items: T[]
}

export function SelectField<T>({
  items = [],
  keyProp,
  valueProp,
  name,
  groupByProp,
  disabled,
  ...props
}: SelectFieldProps<T>) {
  const [, , { setValue }] = useField(name)
  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = items.find(
        (item) => String(item[keyProp]) === event.target.value
      )
      setValue(value)
    },
    [items, keyProp, setValue]
  )

  const getGroups: (items: T[]) => OptionGroup<T>[] = flow(
    groupBy<T>(
      typeof groupByProp === 'function'
        ? (item) => groupByProp(item)
        : (groupByProp as string)
    ),
    toPairs,
    map(([label, items]) => ({ label, items }))
  )

  const renderOption = useCallback(
    (item: T) => (
      <option key={String(item[keyProp])} value={String(item[keyProp])}>
        {typeof valueProp === 'function' ? valueProp(item) : item[valueProp]}
      </option>
    ),
    [keyProp, valueProp]
  )

  const renderOptionGroup = useCallback(
    (group: OptionGroup<T>) => (
      <optgroup key={group.label} label={group.label}>
        {group.items.map(renderOption)}
      </optgroup>
    ),
    [renderOption]
  )

  return (
    <Field name={`${name}[${String(keyProp)}]`}>
      {({ field /*form, meta*/ }: FieldProps) => (
        <Form.Control
          {...field}
          value={field.value || ''}
          as="select"
          custom
          onChange={changeHandler}
          disabled={disabled}
        >
          {(items.length === 0 || !field.value) && (
            <option value="" disabled={items.length > 0 || !!field.value}>
              {props.placeholder ?? `Выберите ...`}
            </option>
          )}
          {groupByProp
            ? getGroups(items).map(renderOptionGroup)
            : items.map(renderOption)}
        </Form.Control>
      )}
    </Field>
  )
}
