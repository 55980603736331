import styled from 'styled-components'

import Base from './bottom_section'

export * from './bottom_section'

export const BottomSection = styled(Base)`
  background-color: ${(props) => props.theme.headerBackgroundPrimaryColor};
  padding: 5px 0;

  &&& a,
  &&& button {
    color: ${(props) => props.theme.headerSecondaryColor};

    &:hover {
      color: ${(props) => props.theme.headerBackgroundSecondaryColor};
    }
  }

  &&& .sub-menu {
    a,
    button {
      color: ${(props) => props.theme.headerPrimaryColor};
      &:hover {
        color: ${(props) => props.theme.headerBackgroundSecondaryColor};
      }
    }
  }

  /* height: 40px; */
  display: flex;
  align-items: center;
  z-index: 1;
`
