import { useEffect, useMemo } from 'react'
// import debounce from 'lodash/debounce'

import type { AnyFunction } from '../types'
import { debounce } from '../utils/debounce'
import { throttle } from '../utils/throttle'
import { useEvent } from './use-event'

type DebounceFunc = typeof debounce | typeof throttle

function makeDebouncedHook(debounceFn: DebounceFunc) {
  return function useDebounce<Fn extends AnyFunction>(fn: Fn, ms: number) {
    const memoizedFn = useEvent(fn)

    const debouncedFn = useMemo(
      () =>
        debounceFn((...args: Parameters<Fn>) => {
          console.log(args)

          memoizedFn(...args)
        }, ms),
      [memoizedFn, ms]
    )

    useEffect(() => () => debouncedFn.cancel(), [debouncedFn])

    return debouncedFn
  }
}

export const useDebounce = makeDebouncedHook(debounce)
export const useThrottle = makeDebouncedHook(throttle)
