import React from 'react'
import { truncate } from 'lodash/fp'

import { getPostUrl, IBlogPost } from '@monorepo/blog_post_module'
import { CompositButton, Link } from 'App/Components'
import { Card, CardProps, OverlayEnum } from 'App/ui/card'
import { DateBadge } from './date_badge'

export type PostItemProps = CardProps & {
  item: IBlogPost
  height: string | number
  imageHeight: string | number
}

// function calculateLines(el: HTMLElement | null) {
//   if (!el) {
//     return null
//   }

//   const height = el?.offsetHeight
//   if (!height) {
//     return null
//   }

//   const lineHeightString = document.defaultView
//     ?.getComputedStyle(el, null)
//     .getPropertyValue('line-height')
//   if (!lineHeightString) {
//     return null
//   }

//   const lineHeight = parseInt(lineHeightString)
//   if (!lineHeight) {
//     return null
//   }

//   const linesCount = Math.ceil(height / lineHeight)

//   return linesCount
// }

export const PostItem: React.FC<PostItemProps> = ({
  item,
  height,
  imageHeight,
  ...props
}) => {
  const getText = truncate({ length: 100 })

  const url = getPostUrl(item)

  return (
    <Card
      image={item.postThumb}
      href={url}
      title={`Подробно о «${item.title}»`}
      height={height}
      imageHeight={imageHeight}
      overlay={OverlayEnum.FADEZOOMEXTREME}
      badge={<DateBadge value={item.createdAt} />}
      {...props}
    >
      <article>
        <Link to={url} title={`Подробно о «${item.title}»`}>
          {getText(item.title)}
        </Link>
        {item.description && (
          <>
            <hr className="mx-4 my-2" />
            <p>{item.description}</p>
          </>
        )}
        <CompositButton to={url} className="align-self-center">
          Читать далее...
        </CompositButton>
      </article>
    </Card>
  )
}
