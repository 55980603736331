import React, { useMemo } from 'react'
import styled from 'styled-components'
import chunk from 'lodash/chunk'
import sampleSize from 'lodash/sampleSize'
import SwiperCore, { Autoplay, Pagination, SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { IGood } from '@monorepo/application_module'
import { useSwiperControl } from '@monorepo/swiper_module'
import { GoodItem } from './good_item'

type GoodsSliderProps = {
  items: IGood[]
  swiperOptions?: SwiperOptions
  rows?: number
  rowHeight: string | number
}

SwiperCore.use([Autoplay, Pagination])

const defaultSwiperOptions: SwiperOptions = {
  autoplay: { delay: 5000 },
  slidesPerView: 5,
  spaceBetween: 5,
  speed: 1000,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 5,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 5,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 5,
    },
  },
  loop: true,
  // navigation: true,
  // pagination: true,
}

const ItemsGroup = styled.section`
  padding: 5px;
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const GoodsSlider: React.FC<GoodsSliderProps> = ({
  items,
  swiperOptions: mainSwiperOptions,
  rows = 1,
  rowHeight,
}) => {
  const { bind } = useSwiperControl({ stopOnHover: true })

  const groups = useMemo(
    () =>
      chunk(
        items.concat(
          sampleSize(
            items,
            Math.ceil(items.length / rows) * rows - items.length
          )
        ),
        rows
      ),
    [items, rows]
  )

  const swiperOptions = { ...defaultSwiperOptions, ...mainSwiperOptions }

  return (
    <Swiper
      {...swiperOptions}
      {...bind}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      {groups.map((items, row) => (
        <SwiperSlide key={row}>
          <ItemsGroup key={row} className="et-item">
            {items.map((item, col) => (
              <GoodItem key={col} item={item} height={rowHeight} />
            ))}
          </ItemsGroup>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
