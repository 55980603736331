import React from 'react'
import { SwiperOptions } from 'swiper'

import { IBlogPost } from '@monorepo/blog_post_module'

import { Slider } from '../View/Home/Slider'
import { PostItem } from '../View/Home/sections/blog_posts_section/post_item'

export type BlogPostsSliderProps = {
  height: string | number
  imageHeight: string | number
  items: IBlogPost[]
}

const options: SwiperOptions = {
  autoplay: !true,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 15,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
  },
}

export const BlogPostsSlider: React.FC<BlogPostsSliderProps> = ({
  items,
  height,
  imageHeight,
}) => (
  <Slider
    items={items}
    swiperOptions={options}
    renderItem={(item) => (
      <PostItem
        item={item}
        height={height}
        imageHeight={imageHeight}
        $border={false}
        $padding={false}
      />
    )}
  />
)
