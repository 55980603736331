import capitalize from 'lodash/capitalize'

import { ICompany } from '@monorepo/company_module'

export function renderOfficeName(office: ICompany) {
  const { city, street, line1, line2, line3 } = office.address
  const address = [city, street, line1, line2, line3]
    .filter(Boolean)
    .map(capitalize)
    .join(', ')
  return address.length > 0 ? address : office.name
}
