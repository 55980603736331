import React from 'react'
import { FormattedDateParts } from 'react-intl'

type DateBadgeProps = React.HTMLAttributes<HTMLElement> & {
  value?: string | number | Date
}

export const DateBadge: React.FC<DateBadgeProps> = ({ value, ...props }) => (
  <FormattedDateParts value={value} year="numeric" month="short" day="2-digit">
    {(parts) => (
      <div {...props}>
        <span>{parts[0].value}</span>
        <span>{parts[2].value}</span>
        <span>{parts[4].value}</span>
      </div>
    )}
  </FormattedDateParts>
)
