import React from 'react'
import styled from 'styled-components'
import BaseContainer from 'react-bootstrap/Container'

import { ICompany } from '@monorepo/company_module'
import { Link } from 'App/Components'
import { SearchField } from './SearchField'
import { CartMenu } from './CartMenu'
// import { OfficesList } from '../../components/offices_list'

type MiddleSectionProps = {
  $isDark?: boolean
  logoImage: string
  offices?: ICompany[]
}

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.headerBackgroundPrimaryColor};
  padding-top: 10px;
  /* box-shadow: inset ${(props) => props.theme.baseBoxShadow}; */
`

const Container = styled(BaseContainer)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
`

const LogoImage = styled.img`
  height: 70px !important;
  margin-bottom: 5px;
`

export const MiddleSection: React.FC<MiddleSectionProps> = ({
  $isDark,
  logoImage,
  offices,
}) => (
  <Wrapper>
    <Container>
      <div className="hbe header-logo hbe-left">
        <Link to="/">
          <LogoImage src={logoImage} alt="" />
        </Link>
      </div>
      <SearchField $isDark={$isDark} />
      <CartMenu />
    </Container>
    {/* <Container>
      <OfficesList offices={offices} />
    </Container> */}
  </Wrapper>
)
