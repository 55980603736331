import { useEffect, useRef } from 'react'
import { useDebounce, useThrottle } from './use-debounce'

type DebounceHook = typeof useDebounce | typeof useThrottle

function makeDebounceEffect(useDebounceHook: DebounceHook) {
  return function (
    cb: React.EffectCallback,
    deps: React.DependencyList,
    ms: number
  ) {
    const cleanUp = useRef<(() => void) | void>()
    const effectCb = useDebounceHook(() => {
      cleanUp.current = cb()
    }, ms)

    useEffect(() => {
      console.log('Run useEffect')

      effectCb()

      return () => {
        if (typeof cleanUp.current === 'undefined') {
          return
        }
        cleanUp.current()
        cleanUp.current = undefined
      }
    }, [effectCb, deps])
  }
}

export const useDebounceEffect = makeDebounceEffect(useDebounce)
export const useThrottleEffect = makeDebounceEffect(useThrottle)
