import React from 'react'
import { Col, Form } from 'react-bootstrap'
import { Field, Form as FormikForm } from 'formik'
import formatISO from 'date-fns/formatISO'

import {
  useContracts,
  useOperationsFilterForm,
} from '@monorepo/contract_module'
import { AutoSave, SelectField } from 'App/Components/FormFields'

type FilterFormProps = {
  //
}

export const FilterForm: React.FC<FilterFormProps> = () => {
  const { data: contracts = [] } = useContracts()
  const { values } = useOperationsFilterForm()

  return (
    <Form as={FormikForm}>
      <AutoSave debounceMs={250} />
      <Form.Row className="align-items-center">
        <Col xs="auto" className="mt-2">
          <Form.Label>Договор</Form.Label>
          <SelectField
            items={contracts}
            name="contract"
            keyProp="number"
            valueProp="name"
          />
        </Col>

        <Col xs="auto" className="mt-2">
          <Form.Label>Начало периода</Form.Label>
          <Field
            as={Form.Control}
            type="date"
            name="startDate"
            placeholder="Начало периода"
            max={values.endDate}
          />
        </Col>

        <Col xs="auto" className="mt-2">
          <Form.Label>Конец периода</Form.Label>
          <Field
            as={Form.Control}
            type="date"
            name="endDate"
            placeholder="Конец периода"
            min={values.startDate}
            max={formatISO(new Date(), { representation: 'date' })}
          />
        </Col>
      </Form.Row>
    </Form>
  )
}
