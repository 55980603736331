import React from 'react'
import styled from 'styled-components'

type CardProps = {
  title?: string
}

const Wrapper = styled.div`
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
  padding: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: flex-start;
  border-radius: ${(props) => props.theme.baseBorderRadius};
  height: 100%;
`

const Title = styled.h4`
  margin-bottom: 0;
  color: ${(props) => props.theme.colorMain};
`

const Separator = styled.span`
  margin-top: 8px;
  margin-bottom: 24px;
  border-bottom-color: ${(props) => props.theme.colorMain};
  border-bottom-style: solid;
`

export const Card: React.FC<CardProps> = ({ title, children }) => (
  <Wrapper>
    {title && (
      <>
        <Title>{title}</Title>
        <Separator />
      </>
    )}

    {children}
  </Wrapper>
)
