import React from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'

import { IGood } from '@monorepo/application_module'
import { getSearchUrl } from '@monorepo/application_module/helpers'
import { CompositButton } from 'App/Components'

type BrandItemProps = {
  item: IGood
}

const Wrapper = styled.li`
  padding: 12px 24px;
  border-bottom: 1px dotted ${(props) => props.theme.colorMain};

  &:last-child {
    border-bottom: none;
  }

  & a {
    & h5 {
      color: ${(props) => props.theme.colorMain};
    }

    & p {
      color: ${(props) => props.theme.colorDarkGrey};
    }

    &:hover {
      text-decoration: none;
    }
  }
`

export const BrandItem: React.FC<BrandItemProps> = ({ item }) => {
  const url = getSearchUrl(item)
  const history = useHistory()

  return (
    <Wrapper>
      <Link to={url}>
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">
            <strong>{item.brand.name}</strong> «{item.number.toUpperCase()}»
          </h5>
          {/* <small>3 days ago</small> */}
          <CompositButton
            // className="btn btn-outline-primary mr-1"
            onClick={(event) => {
              event.preventDefault()
              history.push(url)
            }}
          >
            Выбрать
          </CompositButton>
        </div>
        <p className="mb-1">{item.name}</p>
        {/* <small>{item.name}</small> */}
      </Link>
    </Wrapper>
  )
}
