import styled from 'styled-components'

import Base from './bottom_section'

export * from './bottom_section'

export const BottomSection = styled(Base)`
  /* background-color: ${(props) => props.theme.colorBlack}; */
  background-color: ${(props) => props.theme.colorMain};
  box-shadow: inset ${(props) => props.theme.baseBoxShadow};
  /* border-top: 1px solid ${(props) => props.theme.primary}; */
  border-top: 1px solid ${(props) => props.theme.colorMainDark};

  & > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    padding: 0.5rem 0;
  }

  && *,
  && *:hover {
    color: ${(props) => props.theme.colorWhite};
  }
`
