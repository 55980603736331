import { IMenu } from '@monorepo/menu_module'

export const main: IMenu = [
  {
    name: 'Главная',
    url: '/',
    // exact: true,
  },
  {
    name: 'Новости',
    url: '/blog',
  },
  {
    name: 'Полезное',
    // url: '/about-us',
    menu: [
      {
        name: 'Как оплатить заказ',
        url: '/pages/kak_oplatit_zazkaz',
      },
      {
        name: 'Доставка',
        url: '/pages/dostavka',
      },
      {
        name: 'Обмен и возврат товара',
        url: '/pages/obmen_vozvrat_tovara',
      },
      {
        name: 'Контакты',
        url: '/contacts',
      },
      // {
      //   name: 'О нас',
      //   url: '/about-us',
      // },

      // {
      //   name: '-Оптовым покупателям',
      // },
      // {
      //   name: '-Как оплатить',
      // },
      // {
      //   name: '-Доставка',
      // },
      // {
      //   name: '-Возврат товара',
      // },
      // {
      //   name: 'Каталог товаров',
      //   url: '/goods',
      // },
      {
        name: 'Статьи',
        url: '/blog',
      },
      {
        name: 'Договор оферты',
        url: '/pages/oferta',
      },
      {
        name: 'Пользовательское соглашение',
        url: '/pages/terms-of-use',
      },
      {
        name: 'Использование информации',
        url: '/pages/restrictions-of-use',
      },
      {
        name: 'Обработка персональных данных',
        url: '/pages/processing-personal-data',
      },
      {
        name: 'Политика конфиденциальности',
        url: '/pages/privacy-policy',
      },
    ],
  },
  // {
  //   name: 'Товары',
  //   url: '/goods',
  // },
  // {
  //   name: 'Кабинет',
  //   url: '/cabinet',
  //   menu: [
  //     {
  //       name: 'Перейти в кабинет',
  //       url: '/cabinet',
  //     },
  //     {
  //       name: 'Админпанель',
  //       url: '/admin',
  //       external: true,
  //       roles: ['admin'],
  //     },
  //     {
  //       name: 'Баланс',
  //       url: '/balance',
  //     },
  //     {
  //       name: 'Корзина',
  //       url: '/cart',
  //     },
  //     {
  //       name: 'Заказы',
  //       url: '/orders',
  //     },
  //     // {
  //     //   name: '-Скачать прайс-лист',
  //     // },
  //     {
  //       name: 'Гараж',
  //       url: '/garage',
  //     },
  //     // {
  //     //   name: 'Выход',
  //     //   url: '/logout',
  //     // },
  //   ],
  // },
  // {
  //   name: 'О нас',
  //   url: '/about-us',
  // },
  {
    name: 'Контакты',
    url: '/contacts',
  },
]

export const cabinet: IMenu = [
  {
    name: 'Корзина',
    url: '/cart',
  },
  {
    name: 'Панель управления',
    url: '/cabinet',
  },
  {
    name: 'Профиль',
    url: '/profile',
  },
  {
    name: 'Баланс',
    url: '/balance',
  },
  {
    name: 'Заказы',
    url: '/orders',
  },
  // {
  //   name: '-Скачать прайс-лист',
  // },
  {
    name: 'Гараж',
    url: '/garage',
  },
  {
    name: 'Перейти в админпанель',
    url: '/admin',
    external: true,
    roles: ['admin'],
  },
  // {
  //   name: 'Выход',
  //   url: '/logout',
  // },
]

const footerLinks: IMenu = [
  {
    name: 'Контакты',
    url: '/contacts',
  },
  {
    name: 'О нас',
    url: '/about-us',
  },
  {
    name: 'Статьи',
    url: '/blog',
  },
  {
    name: 'Обмен и возврат товара',
    url: '/pages/obmen_vozvrat_tovara',
  },
  {
    name: 'Доставка',
    url: '/pages/dostavka',
  },
  {
    name: 'Как оплатить заказ',
    url: '/pages/kak_oplatit_zazkaz',
  },
  {
    name: 'Договор оферты',
    url: '/pages/oferta',
  },
  {
    name: 'Пользовательское соглашение',
    url: '/pages/terms-of-use',
  },
  {
    name: 'Использование информации',
    url: '/pages/restrictions-of-use',
  },
  {
    name: 'Обработка персональных данных',
    url: '/pages/processing-personal-data',
  },
  {
    name: 'Политика конфиденциальности',
    url: '/pages/privacy-policy',
  },
]

export const initialState: Record<string, IMenu> = {
  main,
  footerLinks,
  cabinet,
  // userButton,
}
