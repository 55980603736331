import styled from 'styled-components'

export const ItemsGroup = styled.section`
  padding: 5px;
  & > * {
    box-shadow: ${(props) => props.theme.baseBoxShadow};
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
